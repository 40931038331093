.table-violation-full-size .ant-table-header.ant-table-sticky-holder {
  top: 0 !important;
}

.table-violation-full-size {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  width: 100vw;
  background: white;
}
