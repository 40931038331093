.Container {
  overflow-y: auto;
}
.Content {
  transform: rotateX(180deg);
}

.rotateX {
  transform: rotateX(180deg);
}
.compliance-header {
  white-space: pre;
  width: 30%;
  text-align: left;
}
@media (min-width: 1200px) {
    .sidebar {
        max-height: 95vh;
        overflow: scroll;
    }
}

.custom_wrapper li {
  cursor: pointer;
  text-align: center;
  margin: 0 16px 5px 0;
  font-weight: 700;
  border-radius: 1rem;
  border: 1px solid #fff;
  background-color: #ffffff;
  padding: 9px 17px;
  font-size: 16px;
  color: #313131;
}
.custom_wrapper li:hover,
.custom_wrapper li:focus,
.custom_wrapper li:active {
  border-color: #e7e7e7;
  color: #313131;
}
.custom_wrapper li.active {
  background: #ec70ad;
  background: linear-gradient(to bottom, #ec70ad 0%, #ed5a4d 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ec70ad", endColorstr="#ed5a4d", GradientType=0);
  color: #fff;
}

@media screen and (max-width: 767px) {
  .custom_wrapper li {
    padding: 14px 25px;
    font-size: 14px;
    margin: 0 10px 0 0;
  }
}
