.disposition-header div {
    color: #191847;
    margin: 0;
    font-size: 14px;
    font-weight: 700;
}
.disposition-rows div p {
    color: #403f72;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
}
.disposition-rows {
    border-radius: 7px;
    background: #F6F7FB;
    padding: 8px;
    margin-top: 14px;
    margin-left: 0;
    margin-right: 0;
}
