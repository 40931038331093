.pointer {
    cursor: pointer;
}

.activity .media .gradient-round {
    width: 39px;
    height: 39px;
}

.activity .media .gradient-round svg {
    top: 11px;
}
.image-logo {
    height: 35px;
}
.onhover-show-div {
    top: 43px;
    width: 180px;
    padding: 20px;
}
.badgeH5 {
    font-weight: 500;
    font-size: 10px;
    margin-left: 10px;
}

@media only screen and (max-width: 767px) {
   .mobile-center{
     text-align: center!important;
   }
   .mobile-hide{
     display: none;
   }
    .image-logo {
        width: auto;
    }
}
