.todoWrapper {
    background-color: #F6F7FB;
}
.todoWrapper .ant-card-body {
    height: 100px;
    padding: 10px;
}
.dateInfo {
    font-size: 16px;
    line-height: normal;
}
.checkbox-danger input[type="checkbox"]:checked~span {
    background-color: #FF5370;
    border-color: #FF5370;
}
.checkbox-danger input[type="checkbox"]~span {
    border-color: #FF5370;
}
.checkbox-info input[type="checkbox"]:checked~span {
    background-color: #4466F2;
    border-color: #4466F2;
}
.checkbox-info input[type="checkbox"]~span {
    border-color: #4466F2;
}
.checkbox-success input[type="checkbox"]:checked~span {
    background-color: #17BF82;;
    border-color: #17BF82;;
}
.checkbox-success input[type="checkbox"]~span {
    border-color: #17BF82;;
}
.dateInput {
    position: absolute;
    right: 0;
    padding: 2px ;
    font-size: 8px;
    width: 56px;
    font-weight: 400;
    border: 0 solid transparent;
    background: rgba(226, 227, 232, 0.38);
    border-radius: 2px;
}
.dateInput input {
    font-size: 8px;
}
.dateInput input :focus {
    border: 0;
}
.mainInfo {
    font-size: 12px;
    line-height: normal;
}
.verticalDivider {
    height: 100%;
    width: 5px;
    border-radius: 2.5px;
}
.customTab {
    display: inline-flex;
    height: 38px;
    padding: 8px 15px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    margin: 0 5px;
    cursor: pointer;
}
.badgeDanged {
    border-radius: 99px;
    border: 1px solid #FF5370;
    background: #FBE4EA;
}
.badgeInfo {
    border-radius: 99px;
    border: 1px solid #4466F2;
    background: rgba(68, 102, 242, 0.15);
}
.itemType {
    border-radius: 99px;
    position: absolute;
    right: 10px;
    display: flex;
    height: 22px;
    padding: 8px 25px;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: 10px;
    font-weight: 700;
}
.badgeSuccess {
    border-radius: 99px;
    background: #17BF82;
    border: 1px solid #17BF82;
    color: white;
}
.tabsActive {
    background-color: transparent;
    color: #231F20;
}

.customBadge sup {
    color: #231F20;
    border: 1px solid #17BF82;
    background-color:#FFFFFD;

}
/*.todoWrapper::-webkit-scrollbar {*/
/*    width: 3px;*/
/*}*/

/*.todoWrapper::-webkit-scrollbar-thumb {*/
/*    border-radius: 5px;*/
/*    -webkit-box-shadow: inset 0 0 6px #e3dede;*/
/*}*/
