.unit-input {
    width: 45px;
    border-radius: 5px;
    height: 21px;
}
.unit-edit{ 
    cursor: pointer;
    background-color: #f6f7fb;
    padding: 4px;
    border-radius: 5px;
    font: normal normal normal 14px/1 FontAwesome;
}

.unit-edit:hover {
    background-color: #d9dadd;
}

.label-total-units{
    height: 56px;
}
