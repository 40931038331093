.customDashboardHeader {
  font-size: 26px;
  font-weight: 800;
  margin-left: 20px;
}
.customDashboardItem {
  height: 69px;
  border-radius: 7px;
  border: 1px solid white;
  width: 17%;
  margin-left: 2%;
  margin-bottom: 2%;
  background-color: white;
}
@media (max-width: 1500px) {
  .customDashboardItem {
    width: 22%;
  }
}
@media (max-width: 1250px) {
  .customDashboardItem {
    width: 30%;
  }
}
@media (max-width: 700px) {
  .customDashboardItem {
    width: 47%;
  }
}

.customDashboardItemText {
  color: black;
  font-size: 14px;
  font-weight: 400;
  width: 80%;
  
  text-overflow: ellipsis;
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 10px;
}
.customDashboardItemTextBlock {
    text-overflow: ellipsis;
    overflow: hidden;
}

.customDashboardItemHeader {
  border-radius: 7px 0px 0px 7px;
  font-size: 26px;
  font-weight: 700;
  width: 20%;
  color: white;

  background: linear-gradient(90deg, #2e86df, #005BA9 100%);

  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
