.room-wrapper .chat-message {
  /* background: #838181; */
  padding: 20px;
  width: 80%;
  border-radius: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
  clear: both;
  color: #4c4f5a;
}
.room-wrapper .chat-message.sent {
  align-self: end;
  background: #bac6fb;
}
.room-wrapper .chat-message.received {
  align-self: start;
  background: #cdeafb;
}
.room-wrapper .chat-message.received .author {
  text-align: right;
  color: #ababab;
}
.room-wrapper .chat-message .author {
  padding: 10px 0 0;
  font-size: 11px;
  color: #fff;
}
.room-wrapper {
  display: flex;
  flex-direction: column-reverse;
  max-height: 40vh;
  overflow: auto;
}
.border-bottom-header {
  border-bottom: 1px solid #cfd6f6;
}
.chat-viewer-small {
  border-radius: 50%;
  outline: 1px solid #fff;
  background: #1890ff;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: white;
  line-height: normal;
}

.chat-viewer {
  position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  right: 0;
  color: #fff;
  font-size: 16px;
  padding: 2px 5px;
  border-radius: 50%;
  outline: 1px solid #fff;
  background: #1890ff;
}

.chat-viewer:hover {
  z-index: 100;
}
.chat-viewer-static {
  position: static;
  width: 40px;
  height: 40px;
}

.ant-modal-mask,
.ant-modal-wrap {
  z-index: 1200;
}
.room-wrapper .chat-message.chat-message-updated {
  border: 1px solid #cfd6f6;
  border-radius: 10px;
  /* border-top-left-radius: 0; */
  background: none;
}
.members-block {
  height: 40px;
  position: relative;
  cursor: pointer;
}

.hover-effect:hover {
  opacity: 0.75;
}

.friend-list-search input {
  background-color: #fff;
  border: 1px solid #f6f7fb56;
  border-radius: 25px;
  color: #898989;
  letter-spacing: 1px;
  padding: 10px 15px;
  width: 100%;
}
.friend-list-search .fa {
  color: #e8ebf2;
  font-size: 14px;
  position: absolute;
  right: 35px;
  top: 34px;
}
.friend-list-search {
  background-color: #f6f7fb;
  padding: 20px;
  position: relative;
}
.input-send {
  border: 2px solid #4466f2;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.chat-box .people-list .list {
  height: auto;
}
#right_side_bar {
  overflow: auto;
}
.about {
  align-self: center;
}
.loading {
  font-weight: bold;
  display: inline-block;
  font-family: monospace;

  clip-path: inset(0 3ch 0 0);
  animation: l 1.5s steps(4, jump-none) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0)
  }
}