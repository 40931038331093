.wrapper {
  /* font-family: 'Helvetica Light', Helvetica, Arial, sans-serif; */
  max-width: 1082px;
  border-radius: 20px;
  margin: auto;
  font-size: 14px;
  line-height: 1.428571429;
  background-color: #fff;
  border: 1px solid #eaeceb;
  margin: 20px;
}
.btn-safe {
  background-color: #224158;
  color: white;
}
.checkbox-safe {
  width: 23px;
  height: 23px;
  margin-top: 5px;
}
.nocode .ant-input {
   height:50px
}


.blue_top {
  border-radius: 20px 20px 0px 0px;
  background-color: #224158;
  width: 100%;
  padding: 5px;

  padding-bottom: 10px;
  color: white;
}

.wrapper h1 {
  /* font-family: Helvetica Bold, Arial, sans-serif; */
  font-size: 21pt;

  margin-left: 20px;
  margin-right: 20px;
  margin-top: 0px;
  padding-top: 25px;
  font-size: 2em;
}
.wrapper h2 {
  /* font-family: Helvetica Light, Arial, sans-serif; */
  font-size: 21pt;

  margin-left: 20px;
  margin-right: 20px;
  margin-top: 0px;
  font-weight: normal;
  font-size: 1.5em;
}
.wrapper .questions {
  color: black;
  padding: 15px;
  background: #eaeceb;
  border-radius: 0px 0px 20px 20px;
}
.sigCanvas {
  border: 1px solid black;
}

.wrapper .card {
  background: #eaeceb;
}
