.chat-wrapper .messages-wrapper .chat-message {
    background: #eee;
    padding: 10px;
    width: 60%;
    border-radius: 10px;
    margin-bottom: 10px;
    clear: both;
    color: #4c4f5a;
    position: relative;
}
.chat-wrapper .messages-wrapper .chat-message.sent {
    float: left;
    background: #bac6fb;
}
.chat-wrapper .messages-wrapper .chat-message.received {
    float: right;
    background: #cdeafb;
}
.chat-wrapper .messages-wrapper .chat-message.received .author {
    text-align: right;
    color: #ababab;
}
.chat-wrapper .messages-wrapper .chat-message .author {
    padding: 10px 0 0;
    font-size: 11px;
    color: #fff;
}
.chat-wrapper .messages-wrapper .chat-message .chat-badge {
    position: absolute;
    top: -10px;
    right: 0;
    color: #fff;
    background: #000;
    font-size: 10px;
    padding: 2px 5px;
    border-radius: 5px;
}
.chat-wrapper .messages-wrapper .chat-message .chat-badge.chat-badge-new {
    background: #f00;
}
.chat-wrapper .messages-wrapper .chat-message .chat-badge.chat-badge-private {
    background: #505050;
}
.chat-wrapper .send-message {
    display: block;
    float: left;
    width: 100%;
    margin-top: 15px;
    border-top: 1px solid #ddd;
    padding-top: 15px;
}
