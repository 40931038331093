.active-hide {
    background: #4466F2;
    color: white;
}

.no-custom {
 background: rgba(177, 176, 176, 0.2);

}
.menu-item-all {
    padding: 5px 12px;
}
.hide-dropdown .ant-dropdown-menu-item {
    padding: 0;
}