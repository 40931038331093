.min-width-130 {
 min-width: 130px;
}

.flex-elem-search .ant-input-group-addon {
    border: none;
}
.term-icon {
    opacity: 0.7;
}
.term-icon:hover {
    opacity: 1;
    color: rgb(197, 117, 43);
}
