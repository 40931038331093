.questionAnswer:hover {
  background-color: rgb(247, 245, 245);
}

.questionAnswer {
    border-radius: 5px;
    padding: 2px;
}

.question-answer {
  border-bottom: 1px solid rgb(194, 192, 192);
}

.question-block {
  border-bottom: 2px dashed rgb(194, 192, 192);
}

.text-bold {
  font-weight: 1000;
}