.height-40 {
  height: 40px;
}
.w-250px {
    width: 250px;
}
.w-100px {
    width: 100px;
}
.ptb-4px{
    padding-top: 4px;
    padding-bottom: 4px;
}
.posible-var:hover {
  color: #4466f2;
}
