.ant-table-thead .ant-table-cell {
    font-weight: bold;
    font-size: medium;
}
.width-200{
    width: 200px
}

#partners .ant-collapse-header{
    padding-bottom: 12px;
    padding-left: 10px;
    align-items: center;
}

#partners .api-key-input {
    width: 400px
}

#partners .appWork-keys .api-key-input {
    width: 250px
}
.api-key-input.valid {
    border-color: green;
}
.api-key-input.invalid {
    border-color: red;
}


.min-height-40px {
    min-height: 40px;
}

.appWork-panel .ant-collapse-arrow {
   color: white;
}
