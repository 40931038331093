@font-face {
  font-family: "itcavantgardestd-bk";
  src: url("../../../../assets/fonts/itc-avant-garde-gothic/itcavantgardestd-bk.woff2") format("woff2"),
    url("../../../../assets/fonts/itc-avant-garde-gothic/itcavantgardestd-bk.woff") format("woff"),
    url("../../../../assets/fonts/itc-avant-garde-gothic/itcavantgardestd-bk.ttf") format("truetype");
}
@font-face {
  font-family: "itc-avant-garde-gothic-lt-demi";
  src: url("../../../../assets/fonts/itc-avant-garde-gothic/itc-avant-garde-gothic-lt-demi.woff2") format("woff2"),
    url("../../../../assets/fonts/itc-avant-garde-gothic/itc-avant-garde-gothic-lt-demi.woff") format("woff"),
    url("../../../../assets/fonts/itc-avant-garde-gothic/itc-avant-garde-gothic-lt-demi.ttf") format("truetype");
}
body {
  background: rgb(204, 204, 204);
}
div.page {
  font-family: "itcavantgardestd-bk";
  background: white;
  width: 10.5in;
  height: 14in;
  display: block;
  margin: 10px auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  position: relative;
}

div.page strong {
  font-family: "itc-avant-garde-gothic-lt-demi";
}

@media print {
  body,
  div.page {
    margin: 0!important;
    box-shadow: 0;
    height: 100%;
    width: 100%;
    background: white;
    position: relative!important;
    -webkit-print-color-adjust:exact!important;
  }

  .workOrderPrintPage .footer {
    position: fixed!important;
  }
}

.workOrderPrintPage .wrapper {
  height: 100%;
  position: relative;
  padding: 20px;
}

.workOrderPrintPage a {
  text-decoration: none;
  color: #070157;
  transition: 0.5s ease;
}

.workOrderPrintPage .alignright {
  float: right;
  margin-left: 15px;
  margin-bottom: 10px;
}
.workOrderPrintPage .alignleft {
  float: left;
  margin-right: 15px;
  margin-bottom: 10px;
}

.workOrderPrintPage .logo {
  float: left;
}
.workOrderPrintPage .logo img {
  max-height: 100px;
  max-width: 179px;
}

.workOrderPrintPage .header-bg {
  float: right;
  background: #005ba9;
  width: 73%;
  height: 20px;
  position: relative;
  margin: 10px 0 0 0;
}
.workOrderPrintPage .header-bg::after {
  content: "";
  position: absolute;
  bottom: -6px;
  width: 100%;
  border-bottom: 2px solid #005ba9;
}
.workOrderPrintPage .order-heading {
  padding: 30px 0;
  text-align: center;
}
.workOrderPrintPage .order-heading p {
  font-size: 14px;
  font-family: "itc-avant-garde-gothic-lt-demi";
}
.workOrderPrintPage .billing {
  padding: 0 0 30px 0;
}

.workOrderPrintPage .billing-left ul li {
  font-size: 14px;
  margin-bottom: 20px;
  padding: 0 0 0 35px;
  position: relative;
}
.workOrderPrintPage .billing-left ul li::before {
  background: #005ba9;
  color: #fff;
  font-family: FontAwesome;
  content: "\f041";
  font-size: 14px;
  padding: 6px 0 6px 0;
  border-radius: 120px;
  position: absolute;
  left: 0;
  width: 25px;
  text-align: center;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.workOrderPrintPage .billing-left ul li:nth-child(2n)::before {
  font-family: FontAwesome;
  content: "\f015";
}
.workOrderPrintPage .billing-left ul li h2 {
  margin-bottom: 7px;
  font-size: 14px;
  font-family: "itc-avant-garde-gothic-lt-demi", sans-serif;
}
.workOrderPrintPage .billing-left ul li p {
  font-size: 14px;
}

.workOrderPrintPage .billing-right ul li {
  font-size: 14px;
  margin-bottom: 20px;
  padding: 0 0 0 35px;
  position: relative;
}
.workOrderPrintPage .billing-right ul li::before {
  background: #005ba9;
  color: #fff;
  font-family: FontAwesome;
  content: "\f007";
  font-size: 14px;
  padding: 6px 0 6px 0;
  border-radius: 120px;
  position: absolute;
  left: 0;
  width: 25px;
  text-align: center;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.workOrderPrintPage .billing-right ul li:nth-child(2n)::before {
  content: "\f044";
  font-size: 14px;
}
.workOrderPrintPage .billing-right ul li:nth-child(3n)::before {
  content: "\f021";
  font-size: 14px;
}
.workOrderPrintPage .billing-right ul li h2 {
  margin-bottom: 7px;
  font-size: 14px;
  font-family: "itc-avant-garde-gothic-lt-demi", sans-serif;
}
.workOrderPrintPage .billing-right ul li p {
  font-size: 14px;
}
.workOrderPrintPage .billing-status {
  padding: 0 0 50px 0;
}
.workOrderPrintPage .billing-status ul li {
  display: inline-block;
  margin-right: 12%;
  font-size: 14px;
}
.workOrderPrintPage .billing-status ul li:last-child {
  margin-right: 0;
}
.workOrderPrintPage .billing-content h2 {
  font-size: 14px;
  margin-bottom: 7px;
  font-family: "itc-avant-garde-gothic-lt-demi", sans-serif;
}
.workOrderPrintPage .billing-content p {
  font-size: 14px;
  margin-bottom: 30px;
  line-height: 1.3em;
}
.workOrderPrintPage .billing-uploads h2 {
  font-size: 14px;
  margin-bottom: 15px;
  font-family: "itc-avant-garde-gothic-lt-demi", sans-serif;
}
.workOrderPrintPage .billing-uploads ul li {
  float: left;
  margin: 0 15px 15px 0;
}
.workOrderPrintPage .billing-uploads ul li img {
  width: 200px;
  max-height: 250px;
  float: left;
  height: auto;
}
.workOrderPrintPage .billing-uploads ul li:nth-child(4n) {
  margin-right: 0;
}

.workOrderPrintPage .footer {
  position: absolute;
  width: 95%;
  bottom: 0;
  border-bottom: 20px solid #005ba9;
  left: 20px;
  text-align: right;
}
.workOrderPrintPage .footer a {
  font-size: 12px;
}
