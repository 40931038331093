.w-60 {
    width: 60%;
}
.doc-image{
    max-height: 75vh;
    max-width: 100%;
}
.w-30 {
    width: 30%;
}
.max-width-350px{
    max-width: 350px ;
}
.min-height-75vh {
    min-height: 75vh ;
}

.chat-wrapper-main .messages-wrapper {
    height: 51vh;
    overflow: auto;
    padding: 5px;
}
.chat-wrapper-main .chat-wrapper .messages-wrapper .chat-message {
    width: 100%;
}

@media (max-width: 900px) { 
    .chat-wrapper-main .messages-wrapper {
        height: auto;
    }

    .min-height-75vh {
        min-height: auto ;
    }
    .w-70 {
        width: 100%;
    }
    .w-30 {
        width: 100%;
    }
 }
