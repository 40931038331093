
.chart-wrapper {
  width: 25%; 
}
.chart-wrapper-unique{
  width: 100%;
}
.unique-header-text{
  width: 89.5%;
}

