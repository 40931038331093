.color-menu-edit-btn {
    margin-top: 15px;
    width: 150px;
}
.edit-block{
    border-top:1px solid gray;
    width: 95%;
}
.color-menu-item-edit-icon {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    margin-right: 4px;
    transition: opacity 0.15s ease-in-out;
    cursor: pointer;
}

.color-menu-item-edit-icon:hover {
    opacity: 0.65;
}

.color-menu-item-edit{
    margin-bottom: 8px;
    padding: 4px;
    width: 90%;
    border:1px solid #ccc9c9;
    border-radius: 4px;
    position:relative;
}
.color-menu-item-edit-new {
    transition: background-color 0.15s ease-in-out;
    cursor: pointer;
}
.color-menu-item-edit-new:hover {
    background-color: #e6e6e6;
    
}
.color-menu-item-edit input {
    width: 90%;
    border: none;
}
.btn-remove {
    position: absolute;
    right: -20px;
    cursor: pointer;
}
.color-menu-item-edit-text{
    width: 90%;
}
