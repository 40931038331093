.head-address h5 {
  font-size: 26px;
  color: #403f72;
  font-weight: 700;
}
.head-address a {
  text-decoration: none;
  color: inherit;
}
.head-address-detail {
  padding: 0 20px;
}
.head-address-detail .col-form-label {
  color: #403f72;
  font-weight: 700;
  padding: 0 0 0 5px;
}
.head-address-detail .form-control-static {
  color: #403f72;
  display: flex;
  font-weight: 700;
}
.head-address-detail .form-control-static .btn-outline-info {
  color: #403f72;
  font-weight: 700;
  border: none;
  font-size: 14px;
  padding: 0;
}
.head-address-detail .form-control-static .btn-outline-info:hover {
  background: none !important;
  color: #403f72;
}
.head-address-detail .btn {
  padding: 8px 20px;
}
.head-address-detail .link-btb {
  min-width: 110px;
}
.head-address-detail .back-btn {
  background: none !important;
  padding: 0;
  border-radius: 0;
  font-size: 20px;
  color: #403f72 !important;
  border: none !important;
}
.head-address-detail h5 {
  font-size: 26px;
  color: #403f72;
  font-weight: 700;
}
.head-address-detail a {
  text-decoration: none;
  color: inherit;
}
.building-detail {
  padding-left: 25px;
  padding-right: 25px;
}
.building-detail .budge-absolute-right {
  position: absolute;
  right: 20px;
}
.building-detail .custom-field-title {
  font-size: 12px;
  font-weight: 100;
  color: #8b9499;
}
.building-detail .card-section {
  border-radius: 7px;
  background: #fff;
  margin: 20px 0;
}
.building-detail .badge-absolute-right {
  position: absolute;
  right: 20px;
}
.building-detail #all-tabs-area .nav-item {
  background: #fff;
}
.building-detail #all-tabs-area .nav-item a {
  color: #2a3142;
}
.building-detail #all-tabs-area .nav-item .active {
  background: #e7e8ef;
  border-top: 2px solid #005ba9;
}
.building-detail #all-tabs-area .nav-item .nav-link:hover {
  background: #f9f9f9;
  border-top: 2px solid #cacfe1;
}
.building-detail .card {
  box-shadow: none;
  background-color: transparent;
}
.building-detail .card .ant-picker, .building-detail .card .ant-select-selector, .building-detail .card .ant-input, .building-detail .card .violations-picker, .building-detail .card .ant-input-number {
  border-radius: 5px;
}
.building-detail .card .card-header {
  border-bottom: 1px solid #dfdfdf;
  padding: 15px;
}
.building-detail .card .card-body {
  background: #fff;
  border-radius: 7px;
  padding: 20px;
}
.building-detail .card .card-body textarea {
  background-color: #fcfdff;
  resize: none;
  min-height: 100px;
}
.building-detail .row-body {
  background: #f5f6fb;
  padding: 30px 20px;
  border-top: 1px solid #dfdfdf;
}
.building-detail .row-body .col-xl-3 {
  padding-left: 10px;
  padding-right: 10px;
}
.building-detail .row-body .card {
  padding: 20px 12px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 10px #e4e4e4;
}
.building-detail .row-body .card h5 {
  font-size: 14px;
  font-weight: 700;
  color: #6a8aff;
}
.building-detail .violation-head ul li {
  display: inline-block;
  font-weight: 700;
  font-size: 12px;
}
@media (max-width: 1400px) {
  .building-detail .violation-head ul li {
    font-size: 10px;
 }
}
.building-detail .violation-head ul li:first-child {
  width: 50%;
}
.building-detail .violation-head ul li:nth-child(2) {
  float: right;
}
.building-detail .violation-head ul li:nth-child(3) {
  float: right;
  margin-right: 7px;
}
@media (max-width: 1400px) {
  .building-detail .violation-head ul li:nth-child(3) {
    margin-right: 5px;
 }
}
.building-detail .log-content .logs-element {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 5px;
  margin-bottom: 7px;
}
.building-detail .log-content .logs-element .pull-right {
  width: 40%;
}
@media (max-width: 1400px) {
  .building-detail .log-content .logs-element .pull-right {
    width: 40%;
    top: 3px;
    position: relative;
 }
}
.building-detail .log-content .logs-element .pull-right #UncontrolledTooltipOpen {
  color: #3e5565;
  padding: 2px 0;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  width: 50%;
  font-size: 12px;
}
@media (max-width: 1400px) {
  .building-detail .log-content .logs-element .pull-right #UncontrolledTooltipOpen {
    font-size: 10px;
 }
}
.building-detail .log-content .logs-element .pull-right #UncontrolledTooltipClosed {
  float: right;
  width: 50%;
  text-align: center;
  padding: 2px 0;
  font-size: 12px;
  cursor: pointer;
}
@media (max-width: 1400px) {
  .building-detail .log-content .logs-element .pull-right #UncontrolledTooltipClosed {
    font-size: 10px;
    width: 21px;
 }
}
.building-detail .log-content .logs-element:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.building-detail .logs-element {
  margin-bottom: 5px;
}
.building-detail .logs-element span {
  margin-left: 0;
  font-size: 12px;
  letter-spacing: 0;
  color: #1c1b49;
}
@media (max-width: 1400px) {
  .building-detail .logs-element span {
    font-size: 10px;
 }
}
.violation-detail {
  background: #f5f6fb;
  padding: 30px 20px;
}
.violation-detail .violation-head {
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.violation-detail .back-btn {
  background: none !important;
  padding: 0;
  border-radius: 0;
  font-size: 40px;
  color: #403f72 !important;
  border: none !important;
}
.violation-detail h4 {
  font-size: 26px;
  color: #191847;
  font-weight: 700;
  margin: 0;
}
.violation-detail h5 {
  font-size: 18px;
  color: #403f72;
  font-weight: 700;
}
.violation-detail .head-address-detail {
  display: none;
}
.violation-detail .card {
  background: none;
  flex-direction: inherit;
}
.violation-detail .filter-sec {
  position: absolute;
  top: 10px;
  right: 35px;
}
@media only screen and (max-width: 768px) {
  .violation-detail .filter-sec {
    display: none;
 }
}
.violation-detail .card-body {
  padding: 10px !important;
}
.violation-detail .card-body .default-btn {
  background: #567aff;
  color: #fff !important;
}
.violation-detail .card-body .h-100 {
  height: 90% !important;
}
.violation-detail .my-building .card-body {
  background: #fff !important;
  padding: 30px !important;
  border-radius: 10px !important;
}
.violation-detail .my-building .card-body .table thead th {
  border: none !important;
}
.violation-detail .my-building .ptb-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.violationsDetails-bg {
  background: #f6f7fb;
}
.violations-bg {
  background: #f6f7fb;
}
.violations-bg .card {
  background: transparent;
}
.violations-bg .card .head-counter h6 {
  color: #191847;
  font-size: 15px;
  padding: 9px 0 0 0;
}
.violations-bg .card .head-counter span {
  color: #000;
}
.violations-bg .card .card-header {
  background: transparent;
  border: none;
  padding: 30px 10px 30px 10px;
}
.violations-bg .card .card-body {
  background: transparent;
  padding: 0 10px 0 10px;
}
.violations-bg .card .card-body .custom-col .card {
  background: none;
}
.violations-bg .card .card-body .custom-col .card h4 {
  background: #ec718b;
  color: #fff;
  font-size: 18px;
  margin: 0;
  padding: 15px 0 20px 20px;
  border-radius: 10px;
}
.violations-bg .card .card-body .custom-col .card-body {
  box-shadow: 0 0 5px #c4c4c4;
  margin: -10px 0 0 0;
  background: #fff;
  padding: 25px;
}
@media only screen and (max-width: 768px) {
  .violations-bg .card .card-body .custom-col .card-body {
    padding: 15px 10px;
 }
}
.violations-bg .card .card-body .vio-listing .fix-card {
  min-height: auto;
}
.violations-bg .card .card-body .vio-listing .card-body {
  padding: 20px 20px 35px 20px;
  border-radius: 15px;
  background: #fff;
}
.violations-bg .card .card-body .vio-listing .card-body .media-body h4 {
  background: #ec718b;
  color: #fff !important;
  font-size: 18px;
  margin: 0;
  padding: 15px;
  border-radius: 10px;
  float: left;
}
.violations-bg .card .card-body .vio-listing .card-body .media-body .permits-margin-left {
  margin: 0px 0 0 15px;
}
.violations-bg .card .card-body .vio-listing .card-body .media-body h6 {
  display: inline-block;
  margin: 0px;
  padding: 5px 0 0 0;
}
.violations-bg .card .card-body .vio-listing .card-body .media-body h6 span {
  float: none;
  display: block;
  font-size: 20px;
  font-weight: 700;
  margin: 5px 0 0 0;
}
.violations-detail {
  background: #f6f7fb;
  padding: 20px;
}
.violations-detail .violations-head {
  margin-bottom: 20px;
  padding-left: 10px;
}
.violations-detail .violations-head .back-btn {
  background: none !important;
  padding: 0;
  border-radius: 0;
  font-size: 40px;
  color: #403f72 !important;
  border: none !important;
}
.violations-detail .violations-head h5 {
  font-size: 26px;
  color: #403f72;
  font-weight: 700;
}
.violations-detail .violations-head .btn {
  padding: 1px 5px;
  color: #567aff;
  background-color: #e9eeff;
}
.violations-detail .violations-head .btn.active {
  background-color: #567aff;
  color: #e9eeff;
}
.violations-detail .card {
  background: #fff;
  padding: 20px;
}
.violations-detail .card .card-body {
  padding: 0;
}
.violations-detail .card .card-body h3 {
  margin-bottom: 0.2rem;
}
.violations-detail .card .card-body .table thead th {
  border-top: none;
  border-bottom: none;
  color: #191847;
  font-weight: 700;
  padding: 0 0px 20px 0 !important;
  font-size: 15px;
}
.violations-detail .card .card-body .table td {
  color: #191847;
  font-weight: 700;
  border: none;
}
.violations-detail .card .card-body .table td:nth-child(2) {
  text-align: center;
  width: 10%;
}
.violations-detail .card .card-body .table td i {
  color: #6b8aff;
  font-size: 16px;
}
.violations-detail .card .card-body tbody tr {
  border-bottom: 1px solid #e3e3ed;
}
.violations-detail .card.custom-violation-grid {
  background: none;
}
.violations-detail .custom-violation-grid .card-body {
  background: transparent;
}
.violations-detail .custom-violation-grid .card-body .col-xl-3 {
  padding-left: 10px;
  padding-right: 10px;
}
.violations-detail .custom-violation-grid .card-body .pointer {
  background: #fff;
  border-radius: 10px;
  padding: 30px 10px 15px 10px;
  position: relative;
  box-shadow: 0 0 15px #c4c4c4;
}
.violations-detail .custom-violation-grid .card-body .pointer .icon-bg {
 /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ec70b0+0,ed5b4e+100 */
  background: #ec70b0;
 /* Old browsers */
  background: -moz-linear-gradient(top, #ec70b0 0%, #ed5b4e 100%);
 /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #ec70b0 0%, #ed5b4e 100%);
 /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ec70b0 0%, #ed5b4e 100%);
 /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ec70b0', endColorstr='#ed5b4e',GradientType=0);
 /* IE6-9 */
  padding: 15px;
  border-radius: 10px;
  width: 50px;
  text-align: center;
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.violations-detail .custom-violation-grid .card-body .pointer .icon-bg i {
  color: #fff;
  font-size: 24px;
}
.violations-detail .custom-violation-grid .card-body .pointer .thead {
  color: #403f72;
  font-weight: 700;
  text-align: center;
  line-height: 22px;
  font-size: 15px;
  padding: 10px 0;
  display: block;
  border-bottom: 1px solid #e3e3ed;
  margin-bottom: 10px;
  letter-spacing: 0;
}
@media only screen and (max-width: 1500px) {
  .violations-detail .custom-violation-grid .card-body .pointer .thead {
    font-size: 12px;
    line-height: 16px;
    min-height: 70px;
 }
}
.violations-detail .custom-violation-grid .card-body .pointer .found {
  color: #403f72;
  font-weight: 700;
  font-size: 16px;
}
.violations-detail .custom-violation-grid .card-body .pointer .number-bg {
  background: #567aff;
  color: #fff;
  padding: 5px 0;
  width: 30px;
  text-align: center;
  border-radius: 5px;
  font-size: 14px;
  float: right;
}
.calender-bg {
  background: #f6f7fb;
  padding: 20px;
}
.calender-bg .calender-head {
  padding: 20px 0 20px 0;
}
.calender-bg .calender-head .text-right a {
  display: none;
}
.calender-bg .calender-head h5 {
  font-size: 26px;
  color: #403f72;
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: 0;
}
.calender-bg .calender-head a {
  background-color: #4466f2 !important;
  border-color: #4466f2 !important;
  color: #fff;
  padding: 10px 20px;
  display: inline-block;
  border-radius: 120px;
}
.calender-bg .fc-header-toolbar .fc-prev-button {
  float: left;
  background: transparent !important;
  color: #747474 !important;
  border: none !important;
  padding: 0;
}
.calender-bg .fc-header-toolbar .fc-prev-button:active {
  background: transparent;
  border: none;
  padding: 0;
}
.calender-bg .fc-header-toolbar h2 {
  float: left;
  color: #403f72;
  font-size: 16px;
  font-weight: 700;
  margin-top: 4px;
}
.calender-bg .fc-header-toolbar .fc-next-button {
  background: transparent !important;
  color: #747474 !important;
  border: none !important;
  padding: 0;
}
.calender-bg .fc-header-toolbar .fc-next-button:active {
  background: transparent;
  border: none;
  padding: 0;
}
.calender-bg .fc-header-toolbar .fc-button {
  border: 1px solid #ecf5f6;
  background: transparent;
  color: #555582;
  box-shadow: none !important;
}
.calender-bg .fc-header-toolbar .fc-button:active {
  background: transparent !important;
  box-shadow: none;
  border: 1px solid #ecf5f6;
}
.calender-bg .fc-header-toolbar .fc-button:hover {
  box-shadow: none !important;
}
.calender-bg .fc-header-toolbar .fc-button.fc-button-active {
  background: #ecf5f6;
  border: 1px solid #ecf5f6;
  color: #555582;
}
.calender-bg .fc-header-toolbar .fc-button.fc-button-active:focus {
  box-shadow: none !important;
}
.calender-bg table .fc-head .fc-widget-header, .calender-bg table .fc-head th {
  border: none;
  color: #8c8caa;
  text-transform: uppercase;
  font-size: 12px;
}
.calender-bg table .fc-day-top .fc-day-number {
  float: left !important;
  font-size: 11px;
  padding: 5px;
}
.swal2-popup {
  border-radius: 15px !important;
  box-shadow: 0 0 20px #505050;
}
.swal2-popup .swal2-header {
  align-items: first baseline;
}
.swal2-popup .swal2-title {
  color: #403f72;
  font-size: 20px;
  text-align: left;
}
.swal2-popup .swal2-content {
  text-align: left;
}
.swal2-popup .table th, .swal2-popup .table td {
  border: none;
  font-size: 15px;
  padding: 5px 0;
  color: #403f72;
}
.swal2-popup .swal2-styled {
  border-radius: 120px !important;
  background: #4466f2 !important;
}
.swal2-popup .swal2-styled:focus {
  box-shadow: none !important;
}
.hupload .card .card-body {
  padding: 0;
  background: #f5f6fb;
}
.hupload .card .card-body .card-header {
  padding: 30px 20px;
}
.hupload .card .card-body .hupload-head {
  padding: 20px 0 10px 0;
}
.hupload .card .card-body .hupload-head .input-upload label {
  display: inline-block;
  background-color: #2b44ff;
  color: white;
  padding: 7px 25px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
}
.hupload .card .card-body .hupload-head .ipublic {
  color: #403f72;
  font-size: 12px;
  font-weight: 400;
  margin-right: 20px;
}
.hupload .card .card-body .hupload-head .ipublic i {
  margin-right: 5px;
}
@media only screen and (max-width: 767px) {
  .hupload .card .card-body .hupload-head .ipublic {
    display: none;
 }
}
.hupload .card .card-body .hupload-head .iuser {
  color: #2b44ff;
  padding: 10px;
  border-radius: 10px;
  background: #fff;
}
.hupload .card .card-body .hupload-head .iuser i {
  color: #2b44ff;
  font-size: 14px;
}
.hupload .card .card-body .hupload-head .iuser .active {
  background: #2b44ff;
  padding: 2px 7px;
  border-radius: 5px;
  margin: 0 5px;
}
.hupload .card .card-body .hupload-head .iuser .active i {
  color: #fff;
}
.hupload .card .card-body .hupload-content {
  border: none;
  background: #fff;
  padding: 10px 5px;
  margin: 15px 0;
  border-radius: 5px;
  box-shadow: 0 0 10px #e4e4e4;
  font-size: 12px;
}
.hupload #hsearch {
  position: relative;
}
@media only screen and (max-width: 767px) {
  .hupload #hsearch {
    display: none;
 }
}
.hupload #hsearch input {
  position: relative;
  padding: 5px 15px 5px 30px;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
  outline: none;
}
.hupload #hsearch input::placeholder {
  color: #403f72;
  font-weight: 700;
  opacity: 1;
}
.hupload #hsearch::after {
  position: absolute;
  content: "\f002";
  font-family: FontAwesome;
  top: 6px;
  left: 12px;
  color: #403f72;
}
.ecb-head {
  margin-bottom: 0;
}
.ecb-head .ecb-title .list-key {
  width: 70px;
}
.ecb-head .ecb-title h5 {
  color: #403f72;
  font-size: 14px;
  font-weight: 700;
}
.ecb-head .ecb-title h5 span {
  color: #958ea8;
}
.ecb-head .ecb-title .status {
  background: #005ba9;
  color: #fff;
  width: 140px;
  text-align: center;
  border-radius: 5px;
  font-size: 11px;
}
.ecb-head .ecb-title .red-box {
  background: #fceeec;
  text-align: center;
  width: 100%;
  padding: 5px;
}
.ecb-head .ecb-title .red-box h6 {
  color: #f07467;
  margin: 0;
}
.ecb-head .ecb-title .red-box p {
  color: #8983a1;
  margin: 0;
}
.ecb-head .ecb-title .red-box p span {
  color: #514c6f;
  font-weight: 900;
}
.ecb-head .ecb-title .blue-box {
  background: #e9ecff;
  text-align: center;
  padding: 7px 14px;
  border-radius: 5px;
  margin-top: 16px;
}
.ecb-head .ecb-title .blue-box h6 {
  color: #2b44ff;
  margin: 0;
  font-size: 14px;
  font-weight: 900;
}
.ecb-head .ecb-title .blue-box p {
  color: #403f72;
  margin: 0;
  font-weight: 900;
}
.ecb-head .ecb-title .blue-box p span {
  color: #000;
  font-weight: 900;
}
.ecb-head .ecb-sub-head {
  padding-top: 0;
}
.ecb-head .ecb-sub-head h5 {
  color: #191847;
  margin: 0 0 24px 0px;
  font-weight: 800;
  font-size: 22px;
}
.ecb-head .ecb-row {
  margin-bottom: 14px;
  display: flex;
}
.ecb-head .ecb-row h6 {
  color: #191847;
  margin: 0;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 14px;
}
.ecb-head .ecb-row p {
  color: #958ea8;
  margin: 0;
  line-height: 1.6;
  font-size: 13px;
  font-weight: 700;
}
.ecb-head .ecb-notes {
  margin-top: 20px;
  padding-left: 0;
}
.ecb-head .ecb-notes h5 {
  color: #191847;
  margin: 0 0 14px 0px;
  font-weight: 800;
  font-size: 22px;
}
.ecb-head .ecb-notes .desc {
  background: #e5eff3;
  border: 1px solid #daeaee;
  border-radius: 5px;
  padding: 5px;
  border-radius: 10px;
  clear: both;
  color: #403f72;
  position: relative;
}
.ecb-head .ecb-notes .desc .author {
  padding: 10px 0 0;
  font-size: 11px;
  color: #505050;
}
