.link-td{
    position: relative;
    height: 42px;
    background:white
}

.link-block {
    z-index: 1;
    position: absolute;
    max-width: 160px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; 
}
.link-block a:hover {
    text-decoration: underline;
    color: blueviolet;
}
td.link-column {
   padding: 0 !important;
}

.icon-block {
    opacity: 0;
}

.icon-block:hover {
    opacity: 1;
}