.color-menu-item {
    width: 150px;
    min-height: 32px;
    margin-bottom: 8px;
    color: white;
    transition: opacity 0.15s ease-in-out;
    align-self: stretch;
} 

.color-menu-item:hover {
   opacity: 0.75;
   color: white;
}

.color-menu-item-empty {
    background: 'white';
    border: 1px solid rgb(179, 179, 179);
    padding-right: 10px;
    padding-left: 10px;
    width: 100%;
    color: gray;
    justify-content: space-between;
}

.color-menu-item-empty span {
    width: calc(100% - 12px);
}

.color-menu-item-empty i {
    color: black;
    text-align: right; 
}

div .large {
    width: 620px
}

.large.color-menu-item-empty {
    padding-left: 135px;
}