.fa-external-link{
    opacity: 0.5;
    transition: opacity 0.5s;
}

.fa-external-link:hover{
    opacity: 1;
}

.ant-modal-footer, .ant-modal-header {
    border: none;
}

.ant-modal-header {
  
  background-color:#4466f2;

}
.tracking-wrapper {
    background: #f5f6fb;
    padding-left: 35px;
    padding-right: 35px;
}

.ant-modal-title{
    color: white;
    font-size: 23px;
    font-weight: 300;
}
.ant-modal-content {
    border-radius: 5px;
}
.ant-collapse-content>.ant-collapse-content-box {
    padding-left: 12px;
    padding-right: 0px;
   
}
.noHeader .ant-collapse-content>.ant-collapse-content-box {
    padding-left: 0px
}


.ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
    padding-top: 0px; 
}

.panel-header {
    background-color:#4466f2;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 10px 15px;
    font-size: 20px;
    color: white;
}
.ant-collapse-header-text{
    width: 100%
}
.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    padding-bottom: 0px;
    padding-right: 0px;
}


.panel-content {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
    border: 1px solid #4466f2;
}

.panel-info-title {
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 15px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(212,212,212)
}

.rdt_Table .rdt_TableCol {
    font-size: 12px;
}

.ant-panel-panel {
    padding: 0px;
}
.elevator-tracking .ant-tabs {
    width: 100%
}
.elevator-tracking .ant-tabs-top>.ant-tabs-nav {
    margin: 0;
}

.elevator-tracking .ant-tabs-content-holder {
    border-left: 1px solid #f0f0f0;
}